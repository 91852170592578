import React, {Component} from 'react';


export default class ProductDetail extends Component {
    render() {
        return (
            <div style={{margin: 10, flex: 0.8}} >
                <div class="container">
                    dddd
                </div>
            </div>
        )
    }
}